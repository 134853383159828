<template>
  <div class="container">
    <div class="plate-title">邀请码管理</div>
    <a-form ref="formRef" :model="formState" :rules="rules" layout="inline">
      <a-form-item label="医院" name="hospital">
        <a-select :value="formState.hospital" placeholder="请选择医院" style="width: 220px;">
          <a-select-option v-for="(item, index) in listHospital" :key="index" :value="item.id"
            @click="changeHospital1">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="科室" name="department">
        <a-select :value="formState.department" placeholder="请选择科室" style="width: 220px;">
          <a-select-option v-for="(item, index) in listDepartment" :key="index"
            :value="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="邀请码" name="invitationCode">
        <a-input v-model:value="formState.invitationCode" placeholder="请输入邀请码" style="width: 220px;" />
      </a-form-item>
    </a-form>
    <div class="btns">
      <a-button @click="resetForm">重置</a-button>
      <a-button style="margin-left: 10px" type="primary" @click="onSubmit">确认</a-button>
    </div>
  </div>
</template>
<script setup>
  import { computed, ref, reactive, onMounted } from 'vue'
  import { RightOutlined } from '@ant-design/icons-vue';
  import { listDepartments, changeInviteCode } from '@/api/department'
  import { message } from 'ant-design-vue';
  import router from '@/router';

  const formRef = ref();
  const listHospital = ref([])
  const listDepartment = ref([])
  const formState = reactive({
    hospital: null,
    department: '',
    invitationCode: '',
  });

  const rules = {
    hospital: [{ required: true, message: '请选择医院', trigger: 'change' },],
    department: [{ required: true, message: '请选择科室', trigger: 'change' },],
    invitationCode: [{ pattern: /^[0-9a-zA-Z]{4}$/, message: '4位，支持输入数字、字母', trigger: 'change' },
    { required: true, message: '请输入邀请码', trigger: 'change' }],
  };

  const onSubmit = () => {
    formRef.value
      .validate()
      .then(() => {
        changeInviteCode(formState.invitationCode).then((res) => {
          message.success('邀请码设置成功！')
        })
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const resetForm = () => {
    // 获取最近一次保存的值
    getList();
    message.success('邀请码重置成功！')
  };

  function getList() {
    listHospital.value = []
    listDepartment.value = []

    listDepartments().then((res) => {
      formState.hospital = res.results[0].id
      formState.department = res.results[0].departments[0].id
      formState.invitationCode = res.results[0].departments[0].invitationCode

      // 获取医院、科室下拉列表
      res.results.forEach(item => {
        listHospital.value.push({
          name: item.name,
          id: item.id
        })

        item.departments.forEach(dep => {
          listDepartment.value.push({
            name: dep.name,
            id: dep.id
          })
        })
      });
    })
  }

  onMounted(() => {
    getList()
  })
</script>
<style lang="less" scoped>
  .container {
    position: relative;
    height: 100%;

    .btns {
      position: absolute;
      left: 0;
      bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-form-inline .ant-form-item {
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-right: 100px;
  }

  .plate-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 10px 0;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 22px;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: -8px;
      width: 2.5px;
      height: 12px;
      border-radius: 3px;
      background: #0e7ed5;
    }
  }
</style>