import request from '@/utils/request'

// 科室列表
export function listDepartments() {
  return request({
    url: `/api/departments`,
    method: 'get',
  })
}

// 修改科室邀请码
export function changeInviteCode(invitationCode) {
  return request({
    url: `/api/departments/1`,
    method: 'put',
    data: {
      invitationCode
    }
  });
}
